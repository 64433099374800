import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './TestResult.css';
import LogoLolosUjian from '../LogoLolosUjian.png';
import LoadingSpinner from './LoadingSpinner';  // Add this import
import { Helmet } from 'react-helmet';  // Add this import

function TestResult() {
  const [results, setResults] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);  // Add this line
  const navigate = useNavigate();
  const { productId } = useParams();

  useEffect(() => {
    const fetchResults = async () => {
      try {
        setLoading(true);  // Set loading to true when fetching starts
        const user = auth.currentUser;
        if (!user) {
          console.log("No authenticated user");
          navigate('/login');
          return;
        }

        console.log("Fetching results for user:", user.uid, "and product:", productId);
        const docRef = doc(db, 'userTests', `${user.uid}_${productId}`);
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          console.log("Document data:", data);
          setResults(data);
        } else {
          console.log("No such document!");
          setError("No test results found.");
        }
      } catch (err) {
        console.error("Error fetching results:", err);
        setError("An error occurred while fetching results.");
      } finally {
        setLoading(false);  // Set loading to false when fetching ends
      }
    };

    fetchResults();
  }, [navigate, productId]);

  if (loading) return <LoadingSpinner />;
  if (error) return <div className="error">{error}</div>;
  if (!results) return <div className="error">No results available</div>;

  return (
    <div className="test-result">
      <Helmet>
        <title>LolosUjian.com - Hasil Ujian</title>
        <meta name="description" content="Hasil ujian LolosUjian.com" />
      </Helmet>
      <img src={LogoLolosUjian} alt="Lolos Ujian Logo" className="logo" />
      <h2 className="result-title">Hasil Ujianmu</h2>
      <h3 className="product-name">{results.title}</h3>
      <div className="score-breakdown">
        <div className="score-item">
          <h4>TWK</h4>
          <p>{results.twkScore}</p>
        </div>
        <div className="score-item">
          <h4>TIU</h4>
          <p>{results.tiuScore}</p>
        </div>
        <div className="score-item">
          <h4>TKP</h4>
          <p>{results.tkpScore}</p>
        </div>
      </div>
      <div className="button-container">
        <button onClick={() => navigate(`/explanations/${productId}`)} className="explanation-button">
          Lihat Pembahasan
        </button>
        <button onClick={() => navigate('/dashboard/purchased')} className="back-button">
          Kerjakan Tes Lain
        </button>
      </div>
    </div>
  );
}

export default TestResult;