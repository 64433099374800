import axios from 'axios';
import CryptoJS from 'crypto-js'; // Import the crypto-js library

const accessToken = 'EAB249bwXMOkBO7lBjIVtqjrPtD7d4ZBQmFCzobQSUNnvQodhoaZAeguSgtfV26hxbGjgZCZBptCnzeIZCP7EANbqgr4KiWkVRcXYZBTkqmfeb1B4EPiDLa9oISrquV2ImPbAuGNeKT1ckV8AHmayoN4h6OUoZCXfRwq48uZAL8CXdqWZCXK7NTNjezcMFko6Fag5FkQZDZD'; // Replace with your access token
const pixelId = '1299156571072969'; // Replace with your Pixel ID

export const sendConversionEvent = async (eventData) => {
  // Hash user data
  const hashedUserData = {
    em: CryptoJS.SHA256(eventData.user_data.em).toString(),
    ph: CryptoJS.SHA256(eventData.user_data.ph).toString(),
    fn: CryptoJS.SHA256(eventData.user_data.fn).toString(),
    client_user_agent: eventData.user_data.client_user_agent, // Do not hash client user agent
  };

  // Prepare the event data with hashed user data
  const payload = {
    event_name: eventData.event_name,
    event_time: eventData.event_time,
    event_source_url: eventData.event_source_url,
    action_source: eventData.action_source,
    currency: eventData.currency,
    value: eventData.value,
    user_data: hashedUserData, // Use hashed user data
  };

  try {
    const response = await axios.post(`https://graph.facebook.com/v20.0/${pixelId}/events?access_token=${accessToken}`, {
      data: [payload],
    });
    console.log('Conversion event sent:', response.data);
  } catch (error) {
    console.error('Error sending conversion event:', error.response ? error.response.data : error.message);
  }
};