import React from 'react';
import { Helmet } from 'react-helmet';
import './LegalPages.css'; // We'll create this CSS file for shared styles

function Disclaimer() {
  const currentDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

  return (
    <div className="legal-page">
      <Helmet>
        <title>Disclaimer - LolosUjian.com</title>
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6 text-center">Disclaimer</h1>
        <div className="prose max-w-none">
          <p>Last updated: {currentDate}</p>
          <p>The information provided on LolosUjian.com is for general informational and educational purposes only. While we strive to keep the information up to date and correct, we make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability or availability with respect to the website or the information, products, services, or related graphics contained on the website for any purpose.</p>
          <p>LolosUjian.com does not guarantee that using our practice tests will result in passing any actual examinations. The success in any examination depends on various factors, including but not limited to individual effort, study habits, and prior knowledge.</p>
          <p>Any reliance you place on such information is therefore strictly at your own risk. In no event will we be liable for any loss or damage including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of this website.</p>
          <p>Through this website, you may be able to link to other websites which are not under the control of LolosUjian.com. We have no control over the nature, content, and availability of those sites. The inclusion of any links does not necessarily imply a recommendation or endorse the views expressed within them.</p>
          <p>Every effort is made to keep the website up and running smoothly. However, LolosUjian.com takes no responsibility for, and will not be liable for, the website being temporarily unavailable due to technical issues beyond our control.</p>
        </div>
        <hr className="my-8 border-t-2 border-gray-200" />
      </div>
    </div>
  );
}

export default Disclaimer;