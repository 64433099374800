import React from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import LogoLolosUjian from '../LogoLolosUjian.png'; // Import logo
import { FaInstagram, FaEnvelope, FaWhatsapp } from 'react-icons/fa'; // Import social media icons

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-left">
          <Link to="/" className="footer-logo-link">
            <img src={LogoLolosUjian} alt="LolosUjian Logo" className="footer-logo" />
          </Link>
          <h2 className="footer-site-name">LolosUjian.com</h2>
          <p className="footer-address">Jl. Nipah Gg. 3, Jakarta Selatan, 12170</p>
        </div>
        <div className="footer-right">
          <div className="footer-social-media">
            <a href="https://instagram.com/lolosujiancom" target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </a>
            <a href="mailto:tahubahasa@outlook.com">
              <FaEnvelope />
            </a>
            <a href="https://wa.me/+6285183104056" target="_blank" rel="noopener noreferrer">
              <FaWhatsapp />
            </a>
          </div>
          <div className="footer-links">
            <Link to="/disclaimer">Disclaimer</Link>
            <Link to="/terms">Terms and Conditions</Link>
          </div>
          <p className="copyright">&copy; 2024 LolosUjian.com. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
