import React, { useState, useEffect } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCart } from '../CartContext';
import { db, auth } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, doc, setDoc, getDoc, query, where } from 'firebase/firestore';
import { createTransaction, isSnapScriptLoaded } from '../services/MidtransService';
import { formatPrice } from '../utils/formatters';
import './Cart.css';
import { Helmet } from 'react-helmet';
import { sendConversionEvent } from '../services/MetaConversionAPI';

const getCategoryColor = (category) => {
  switch (category) {
    case 'CPNS':
      return '#E9724C';
    case 'IELTS':
      return '#C5283D';
    default:
      return '#4ECDC4';
  }
};

function Cart() {
  const { cart, removeFromCart, clearCart } = useCart();
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const user = auth.currentUser;
  const navigate = useNavigate();
  const [voucherCode, setVoucherCode] = useState('');
  const [appliedVoucher, setAppliedVoucher] = useState(null);
  const [voucherError, setVoucherError] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    console.log('Current user:', user);
    window.scrollTo(0, 0);
  }, [user, location.pathname]);

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, 'products'));
      const productsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setProducts(productsData);
      setLoading(false);
    };
    fetchProducts();
  }, []);

  const getTotalPrice = () => {
    return Object.entries(cart).reduce((total, [productId, quantity]) => {
      const product = products.find(p => p.id === productId);
      return total + (product ? product.price * quantity : 0);
    }, 0);
  };

  const applyVoucher = async () => {
    setVoucherError('');
    setAppliedVoucher(null);

    if (!voucherCode) {
      setVoucherError('Silakan masukkan kode voucher');
      return;
    }

    try {
      const voucherQuery = query(collection(db, 'vouchers'), where('code', '==', voucherCode));
      const voucherSnapshot = await getDocs(voucherQuery);

      if (voucherSnapshot.empty) {
        setVoucherError('Invalid voucher code');
        return;
      }

      const voucherData = voucherSnapshot.docs[0].data();
      const voucherId = voucherSnapshot.docs[0].id;

      if (voucherData.usageLimit <= 0) {
        setVoucherError('This voucher has reached its usage limit');
        return;
      }

      const totalPrice = getTotalPrice();
      if (totalPrice < voucherData.minTransaction) {
        setVoucherError(`Minimum transaction amount for this voucher is ${formatPrice(voucherData.minTransaction)}`);
        return;
      }

      setAppliedVoucher({
        id: voucherId,
        ...voucherData
      });
      setVoucherCode('');
    } catch (error) {
      console.error('Error applying voucher:', error);
      setVoucherError('An error occurred while applying the voucher');
    }
  };

  const removeVoucher = () => {
    setAppliedVoucher(null);
  };

  const calculateDiscountedTotal = () => {
    const total = getTotalPrice();
    if (!appliedVoucher || voucherError) {
      return total;
    }

    if (appliedVoucher.discountType === 'percentage') {
      return total * (1 - appliedVoucher.discountValue / 100);
    } else if (appliedVoucher.discountType === 'fixed') {
      return Math.max(0, total - appliedVoucher.discountValue);
    }
    return total;
  };

  const handleCheckout = async () => {
    // Report conversion to Google Ads
    if (typeof window.gtag_report_conversion === 'function') {
      window.gtag_report_conversion();
    } else {
      console.warn('gtag_report_conversion function not found');
    }

    const eventData = {
      event_name: 'InitiateCheckout',
      event_time: Math.floor(Date.now() / 1000), // Current time in seconds
      event_source_url: window.location.href, // Current URL
      action_source: 'website',
      currency: 'IDR', // Replace with the actual currency
      value: calculateDiscountedTotal(), // Total purchase value
      user_data: {
        em: user.email, 
        fn: user.displayName,
        client_user_agent: navigator.userAgent, // Client user agent
      },
    };

    await sendConversionEvent(eventData);

    try {
      setIsProcessing(true); // Start showing the loading spinner

      if (!user) {
        throw new Error('User is not authenticated');
      }

      if (!isSnapScriptLoaded()) {
        console.error('Snap.js is not loaded');
        throw new Error('Snap.js is not loaded');
      }

      const orderData = {
        userId: user.uid,
        products: cart,
        totalAmount: calculateDiscountedTotal(),
        status: 'pending',
        createdAt: new Date()
      };

      // Safely add voucher information if a voucher is applied
      if (appliedVoucher) {
        orderData.appliedVoucher = {
          code: appliedVoucher.code,
          discountType: appliedVoucher.discountType,
          discountValue: appliedVoucher.discountValue
        };
      }

      const orderRef = await addDoc(collection(db, 'orders'), orderData);

      if (appliedVoucher) {
        // Update voucher usage
        const voucherRef = doc(db, 'vouchers', appliedVoucher.id);
        await updateDoc(voucherRef, {
          usageLimit: appliedVoucher.usageLimit - 1
        });
      }

      const midtransOrderData = {
        orderId: orderRef.id,
        totalAmount: calculateDiscountedTotal(),
        customerName: user.displayName || 'Customer',
        customerEmail: user.email
      };

      console.log('Order data being sent to Midtrans:', midtransOrderData);

      const response = await createTransaction(midtransOrderData);
      console.log('Received response from Midtrans:', response);

      if (!response.token) {
        throw new Error('No token received from Midtrans');
      }

      // Clear the cart immediately after creating the order
      clearCart();

      window.snap.pay(response.token, {
        onSuccess: async function(result) {
          console.log('Payment success:', result);
          try {
            await updateDoc(doc(db, 'orders', orderRef.id), { 
              status: 'paid',
              paymentDetails: result
            });

            // Add purchased products to user's purchased items
            const purchasedProductsRef = doc(db, 'purchasedProducts', user.uid);
            const purchasedProductsSnap = await getDoc(purchasedProductsRef);
            
            if (purchasedProductsSnap.exists()) {
              await updateDoc(purchasedProductsRef, {
                products: [...new Set([...purchasedProductsSnap.data().products, ...Object.keys(cart)])]
              });
            } else {
              await setDoc(purchasedProductsRef, {
                products: Object.keys(cart)
              });
            }

            alert('Payment successful!');
            navigate('/dashboard/transactions');
          } catch (error) {
            console.error('Error updating Firestore after successful payment:', error);
            alert('Payment was successful, but there was an error updating your purchase history. Please contact support.');
          }
          setIsProcessing(false); // Hide the loading spinner
        },
        onPending: async function(result) {
          console.log('Payment pending:', result);
          await updateDoc(doc(db, 'orders', orderRef.id), { 
            status: 'pending',
            paymentDetails: result
          });
          alert('Payment pending. Please complete your payment.');
          navigate('/dashboard/transactions');
          setIsProcessing(false); // Hide the loading spinner
        },
        onError: function(result) {
          console.error('Payment error:', result);
          alert('Payment failed. Please try again.');
          setIsProcessing(false); // Hide the loading spinner
        },
        onClose: function() {
          console.log('Customer closed the popup without finishing the payment');
          alert('Payment cancelled. Please try again.');
          setIsProcessing(false); // Hide the loading spinner
        }
      });
    } catch (error) {
      console.error('Detailed error during checkout:', error);
      alert(`An error occurred during checkout: ${error.message}`);
      setIsProcessing(false); // Hide the loading spinner in case of error
    }
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const cartIsEmpty = Object.keys(cart).length === 0;

  return (
    <div className="cart-container">
      <Helmet>
        <title>LolosUjian.com - Keranjang</title>
        <meta name="description" content="Keranjang belanja LolosUjian.com" />
      </Helmet>
      <div className="cart-header">
        <h2><strong>Keranjangmu</strong></h2>
      </div>
      {!cartIsEmpty && (
        <div className="promo-code-banner">
          <p>Dapatkan diskon 50% menggunakan kode voucher <strong>CPNS50</strong> dengan minimal transaksi Rp30.000,.</p>
        </div>
      )}
      {cartIsEmpty ? (
        <div className="empty-cart">
          <p className="empty-cart-message">Keranjangmu kosong!</p>
          <div className="empty-cart-icon animated">🛒</div>
          <button className="back-to-dashboard" onClick={() => navigate('/dashboard')}>
            Kembali ke Dashboard
          </button>
        </div>
      ) : (
        <div className="cart-content">
          <div className="cart-items">
            {Object.entries(cart).map(([productId, quantity]) => {
              const product = products.find(p => p.id === productId);
              return product ? (
                <div key={productId} className="cart-item">
                  <div className="cart-item-info">
                    <span className="category-label" style={{backgroundColor: getCategoryColor(product.category)}}>{product.category}</span>
                    <div className="product-name-price">
                      <h3 className="product-name">{product.name}</h3>
                      <span className="product-price">{formatPrice(product.price * quantity)}</span>
                    </div>
                    <p className="product-details">
                      <span className="inline-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#255F85" width="16" height="16">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                        </svg>
                        Waktu: {product.time} menit
                      </span>
                      <span className="inline-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#255F85" width="16" height="16">
                          <path strokeLinecap="round" strokeLinejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                        </svg>
                        Total soal: {product.numberOfQuestions} butir
                      </span>
                    </p>
                  </div>
                  <button 
                    className="remove-item" 
                    onClick={() => removeFromCart(productId)}
                    aria-label="Remove item"
                  >
                    <i className="fas fa-trash-alt"></i>
                  </button>
                </div>
              ) : null;
            })}
          </div>
          <div className="cart-summary">
            <div className="voucher-section">
              <input
                type="text"
                value={voucherCode}
                onChange={(e) => setVoucherCode(e.target.value)}
                placeholder="Masukkan kode voucher"
              />
              <button onClick={applyVoucher}>Apply</button>
            </div>
            {voucherError && <p className="error">{voucherError}</p>}
            {appliedVoucher && (
              <div className="applied-voucher">
                <p>Voucher yang digunakan: {appliedVoucher.code}</p>
                <button onClick={removeVoucher} className="remove-voucher" aria-label="Remove voucher">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" width="24" height="24">
                    <path fillRule="evenodd" d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z" clipRule="evenodd" />
                  </svg>
                </button>
              </div>
            )}
            <div className="cart-total">
              <p>Subtotal: <span>{formatPrice(getTotalPrice())}</span></p>
              {appliedVoucher && (
                <p>Discount: <span>
                  {appliedVoucher.discountType === 'percentage' 
                    ? `${appliedVoucher.discountValue}%` 
                    : formatPrice(appliedVoucher.discountValue)}
                </span></p>
              )}
              <p className="final-total">Total: <span>{formatPrice(calculateDiscountedTotal())}</span></p>
            </div>
            <button 
              className="checkout-button" 
              onClick={handleCheckout}
              disabled={isProcessing}
            >
              {isProcessing ? 'MEMPROSES...' : 'LAKUKAN PEMBAYARAN'}
            </button>
          </div>
        </div>
      )}
      {isProcessing && <LoadingSpinner />}
    </div>
  );
}

export default Cart;