import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { collection, getDocs } from 'firebase/firestore';
import { db, auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';
import './Home.css';
import LogoLolosUjian from '../LogoLolosUjian.png';
import { ComputerDesktopIcon, DocumentCheckIcon, ArrowPathIcon, DevicePhoneMobileIcon, ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/outline';
import { Helmet } from 'react-helmet';

function Home() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [products, setProducts] = useState({});
  const [user, setUser] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const [showPromotion, setShowPromotion] = useState(true);
  const promotionMessage = "🎉 Diskon 50% untuk semua paket! Gunakan kode: CPNS50";
  const [animatedText, setAnimatedText] = useState('Ujian');
  const examTypes = ['CPNS', 'IELTS', 'TOEFL', 'Ujian'];
  const [isLoading, setIsLoading] = useState(true);

  const faqs = [
    {
      question: "Apa itu LolosUjian.com?",
      answer: "LolosUjian.com adalah platform penyedia latihan berbagai ujian dengan paket soal premium supaya kamu lebih siap dalam menghadapi ujian yang sebenarnya."
    },
    {
      question: "Apakah setiap paket soal berbeda?",
      answer: "Benar, semua paket soal memiliki pertanyaan yang berbeda sehingga kamu bisa berlatih lebih banyak soal."
    },
    {
      question: "Di mana saya bisa melihat pembahasan soal?",
      answer: "Pembahasan soal akan langsung tersedia setelah kamu selesai mengerjakan setiap paket ujian ya!"
    },
    {
      question: "Bagaimana cara membeli paket soal?",
      answer: "Setelah kamu mendaftar, kamu bisa memilih paket soal pada halaman dashboard yang dapat kamu tembahkan ke keranjang belanja. Setelah itu, kamu bisa melakukan checkout."
    },
    {
      question: "Kapan saya bisa mengerjakan ujian yang sudah saya beli?",
      answer: "Setelah pembelian berhasil, kamu bisa mengerjakan ujian kapan saja yang dapat kamu akses melalui halaman Paket Ujianku."
    },
    {
      question: "Saya sudah membayar, tetapi kenapa status transaksi saya masih pending?",
      answer: "Silakan klik tombol refresh secara berkala pada laman transaksi. Jika pembayaran berhasil, paket soal akan muncul di halaman Paket Ujianku."
    },
    {
      question: "Bagaimana cara melaporkan masalah?",
      answer: "Hubungi tim LolosUjian.com melalui tahubahasa@outlook.com atau melalui WhatsApp di +6285183104056."
    }
  ];

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(db, 'products'));
        const productsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        // Sort products by timestamp (newest first)
        const sortedProducts = productsData.sort((a, b) => {
          return b.createdAt?.toMillis() - a.createdAt?.toMillis();
        });

        const categorizedProducts = sortedProducts.reduce((acc, product) => {
          if (!acc[product.category]) {
            acc[product.category] = [];
          }
          acc[product.category].push(product);
          return acc;
        }, {});
        setProducts(categorizedProducts);
      } catch (error) {
        console.error("Error fetching products:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchProducts();

    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });

    let currentIndex = 0;
    const intervalId = setInterval(() => {
      currentIndex = (currentIndex + 1) % examTypes.length;
      setAnimatedText(examTypes[currentIndex]);
    }, 4000); // Change text every 4 seconds

    return () => {
      unsubscribe();
      clearInterval(intervalId);
    };
  }, []);

  const [currentTestimonial, setCurrentTestimonial] = useState(0);

  const testimonials = [
    { name: 'M. Tirto', text: 'Ujian asli jadi terasa gampang setelah latihan banyak paket soal di sini.', image: 'https://i.ibb.co.com/wpgqyTQ/5.jpg' },
    { name: 'Mega Utari', text: 'Tampilan latihan ujian di sini bikin aku familiar sama tes ujian yang asli.', image: 'https://i.ibb.co.com/17g460j/4.jpg' },
    { name: 'Ethan', text: 'Pembahasan soalnya jelas. Saya jadi paham soal-soal yang sering keluar.', image: 'https://i.ibb.co.com/cc7R7r9/1.jpg' },
  ];

  const toggleFAQ = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  const avatarColors = ['#E9724C', '#C5283D', '#481D24'];

  const generateInitials = () => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    return `${alphabet[Math.floor(Math.random() * 26)]}${alphabet[Math.floor(Math.random() * 26)]}`;
  };

  const avatarInitials = [generateInitials(), generateInitials(), generateInitials()];

  const getCategoryColor = (category) => {
    switch (category) {
      case 'CPNS':
        return '#E9724C';
      case 'IELTS':
        return '#C5283D';
      default:
        return '#4ECDC4'; // Default color for other categories
    }
  };

  return (
    <div className="bg-white font-['DM_Sans'] w-full">
      <Helmet>
        <title>LolosUjian.com - Tryout Paket Soal Premium</title>
        <meta name="description" content="Latihan ujian online untuk persiapan CPNS, IELTS, dan lainnya" />
      </Helmet>
      {showPromotion && (
        <div className="promotion-banner">
          <p>{promotionMessage}</p>
        </div>
      )}
      <nav className="fixed left-0 right-0 z-50 bg-white shadow-md">
        <div className="navbar-content">
          <div className="flex justify-between items-center h-16">
            <div className="flex-shrink-0 flex items-center">
              <img src={LogoLolosUjian} alt="LolosUjian Logo" className="h-8 w-auto mr-2" />
              <span className="text-xl font-bold text-gray-800">LolosUjian.com</span>
            </div>
            <div className="hidden md:block">
              <div className="ml-10 flex items-baseline space-x-4">
                <a href="#products" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">Paket Ujian</a>
                <a href="#features" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">Fitur</a>
                <a href="#testimonials" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">Testimoni</a>
                <a href="#faq" className="text-gray-700 hover:text-gray-900 px-3 py-2 rounded-md text-sm font-medium">FAQ</a>
                <Link 
                  to={user ? "/dashboard" : "/login"} 
                  className="bg-[#FFC857] text-black px-4 py-2 rounded-md text-sm font-medium hover:bg-[#E6B54E]"
                >
                  {user ? "Dashboard" : "Sign In"}
                </Link>
              </div>
            </div>
            <div className="md:hidden">
              <button
                onClick={() => setIsMenuOpen(!isMenuOpen)}
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-[#FFC857] bg-white"
              >
                <span className="sr-only">Open main menu</span>
                {isMenuOpen ? (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                ) : (
                  <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        {isMenuOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
              <a href="#products" className="text-gray-700 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium">Paket Ujian</a>
              <a href="#features" className="text-gray-700 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium">Fitur</a>
              <a href="#testimonials" className="text-gray-700 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium">Testimoni</a>
              <a href="#faq" className="text-gray-700 hover:text-gray-900 block px-3 py-2 rounded-md text-base font-medium">FAQ</a>
              <Link 
                to={user ? "/dashboard" : "/login"} 
                className="bg-[#FFC857] text-black block px-3 py-2 rounded-md text-base font-medium hover:bg-[#E6B54E]"
              >
                {user ? "Dashboard" : "Sign In"}
              </Link>
            </div>
          </div>
        )}
      </nav>

      <main className="pt-16 w-full">
        {/* Hero section */}
        <section className="text-center py-20 w-full">
          <div className="section-content">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="md:w-1/2 md:text-left order-2 md:order-1">
                <h1 className="text-4xl font-bold text-gray-900 sm:text-5xl md:text-6xl mt-8 md:mt-0">
                  <span className="block">Semakin Siap <br></br> 
                    <span className="animated-text-container">
                      Lolos <span className="animated-text"><span className="animated-text-content" key={animatedText}>{animatedText}</span></span>
                    </span>
                  </span>
                </h1>
                <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                  Hadapi ujian CPNS, IELTS, dan lainnya lebih percaya diri dengan tryout paket soal berkualitas berbasis CAT (<i>Computer Assisted Test</i>).
                </p>
                <div className="mt-5 sm:flex sm:justify-center md:justify-start">
                  <div className="rounded-md shadow">
                    <Link to="/register" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#255F85] hover:bg-[#1E4A6A] md:py-4 md:text-lg md:px-10">
                      Cari Ujian
                    </Link>
                  </div>
                </div>
                <div className="mt-6 flex items-center">
                  <div className="flex -space-x-2 overflow-hidden">
                    {avatarInitials.map((initials, index) => (
                      <div 
                        key={index} 
                        className="inline-block h-10 w-10 rounded-full text-white flex items-center justify-center text-sm font-medium border-2 border-white"
                        style={{ backgroundColor: avatarColors[index] }}
                      >
                        {initials}
                      </div>
                    ))}
                  </div>
                  <span className="ml-3 text-sm font-medium text-gray-500">
                    Ikuti jejak sukses 1,248 alumni kami
                  </span>
                </div>
              </div>
              <div className="md:w-1/2 mb-8 md:mb-0 order-1 md:order-2">
                <img src="https://i.ibb.co.com/DgYHdWt/Lolos-Ujian-hero-image.webp" alt="Lolos Ujian Hero Image" className="w-full h-auto object-cover rounded-lg" />
              </div>
            </div>
          </div>
        </section>

        {/* Products section */}
        <section id="products" className="py-12 w-full">
          <div className="section-title-container">
            <h2 className="section-title text-3xl font-extrabold text-gray-900 mb-10">
              Pilihan Ujian
            </h2>
          </div>
          <div className="section-content">
            {isLoading ? (
              <div className="flex justify-center items-center h-64">
                <div className="spinner"></div>
              </div>
            ) : (
              <div className="category-list">
                {Object.entries(products).map(([category, categoryProducts]) => (
                  <div key={category} className="category-section mb-8">
                    <h3 className="category-title text-2xl font-semibold mb-4">{category}</h3>
                    <div className="product-grid-container">
                      <div className="product-grid">
                        {categoryProducts.map(product => (
                          <div key={product.id} className="product-card">
                            {product.isNew && <span className="new-tag">Baru!</span>}
                            <span 
                              className="category-tag" 
                              style={{backgroundColor: getCategoryColor(product.category)}}
                            >
                              {product.category}
                            </span>
                            <div className="product-card-content">
                              <h3>{product.name}</h3>
                              <p>Waktu pengerjaan: {product.time} menit</p>
                              <p>Total soal: {product.numberOfQuestions} butir</p>
                            </div>
                            {product.category === 'IELTS' ? (
                              <button 
                                className="product-card-button disabled"
                                disabled
                              >
                                Segera hadir
                              </button>
                            ) : (
                              <Link 
                                to="/register" 
                                className="product-card-button"
                                style={{ backgroundColor: '#255F85', color: '#ffffff' }}
                              >
                                Mau tes ini
                              </Link>
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </section>

        {/* Features section */}
        <section id="features" className="w-full">
          <div className="section-content">
            <div className="lg:text-center">
              <h2 className="text-base text-[#255F85] font-semibold tracking-wide uppercase">Semua Fitur</h2>
              <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                Lengkap untuk persiapan ujianmu
              </p>
            </div>

            <div className="mt-10">
              <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                {[
                  {
                    name: 'Seperti Ujian Asli',
                    description: 'Tampilan aplikasi kami disesuaikan dengan ujian yang sebenarnya.',
                    icon: ComputerDesktopIcon,
                  },
                  {
                    name: 'Pembahasan Lengkap',
                    description: 'Dapatkan akses pembahasan rinci setiap soal selamanya.',
                    icon: DocumentCheckIcon,
                  },
                  {
                    name: 'Paket Soal Terbaru',
                    description: 'Semua paket soal diperbarui berdasarkan kurikulum yang berlaku.',
                    icon: ArrowPathIcon,
                  },
                  {
                    name: 'Mobile Friendly',
                    description: 'Berlatih kapan saja, di mana saja melalui perangkat apapun.',
                    icon: DevicePhoneMobileIcon,
                  },
                ].map((feature) => (
                  <div key={feature.name} className="relative">
                    <dt>
                      <div className="absolute flex items-center justify-center h-12 w-12 rounded-full bg-[#255F85] text-white">
                        <feature.icon className="h-6 w-6" aria-hidden="true" />
                      </div>
                      <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                    </dt>
                    <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </section>

        {/* Testimonials section */}
        <section id="testimonials" className="w-full py-16 bg-white">
          <div className="section-content">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">
              Kata Mereka yang Berhasil Lolos
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
              {testimonials.map((testimonial, index) => (
                <div key={index} className="bg-gray-100 rounded-lg shadow-lg p-6 flex flex-col items-center">
                  <img src={testimonial.image} alt={testimonial.name} className="w-20 h-20 rounded-full mb-4" />
                  <p className="text-gray-600 text-center mb-4 italic">"{testimonial.text}"</p>
                  <p className="font-semibold text-gray-800">{testimonial.name}</p>
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* FAQ section */}
        <section id="faq" className="w-full py-12">
          <div className="section-content">
            <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-8">
              Frequently Asked Questions
            </h2>
            <div className="faq-list">
              {faqs.map((faq, index) => (
                <div key={index} className="faq-item mb-4">
                  <button
                    className="faq-question w-full text-left text-lg font-medium text-gray-900 bg-gray-100 p-4 rounded-md focus:outline-none hover:bg-[#FFC857] flex justify-between items-center"
                    onClick={() => toggleFAQ(index)}
                  >
                    <span>{faq.question}</span>
                    {activeIndex === index ? (
                      <ChevronUpIcon className="h-5 w-5" />
                    ) : (
                      <ChevronDownIcon className="h-5 w-5" />
                    )}
                  </button>
                  {activeIndex === index && (
                    <div className="faq-answer mt-2 p-4 text-gray-700 bg-gray-50 rounded-md">
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>
        </section>

        {/* Coba Sekarang section */}
        <section className="text-center py-20 w-full">
          <div className="section-content">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <div className="md:w-1/2 mb-8 md:mb-0 order-1 md:pr-8 flex justify-center md:justify-start">
                <img src="https://i.imgur.com/3xABCdF.png" alt="Lolos Ujian SKD CPNS 2024" className="w-3/4 h-auto object-cover rounded-lg" />
              </div>
              <div className="md:w-1/2 md:text-left order-2">
                <h2 className="text-3xl font-extrabold text-gray-900">
                  Coba Sekarang!
                </h2>
                <p className="mt-3 max-w-md mx-auto text-base text-gray-500 sm:text-lg md:mt-5 md:text-xl md:max-w-3xl">
                  Tingkatkan peluangmu untuk lolos ujian dengan mencoba latihan soal sekarang.
                </p>
                <div className="mt-5 sm:flex sm:justify-center md:justify-start">
                  <div className="rounded-md shadow">
                    <Link to="/register" className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#255F85] hover:bg-[#1E4A6A] md:py-4 md:text-lg md:px-10">
                      Mulai Latihan
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="mt-4 border-t-2 border-gray-200 mx-auto w-11/12 sm:w-10/12 lg:w-9/12" />
        </section>
      </main>
    </div>
  );
}

export default Home;