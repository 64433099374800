import CustomMidtransClient from './CustomMidtransClient';

const snap = new CustomMidtransClient({
    isProduction: process.env.NODE_ENV === 'true',
    clientKey: process.env.REACT_APP_MIDTRANS_CLIENT_KEY
});

export const createTransaction = async (orderData) => {
    try {
        const response = await snap.createTransaction({
            transaction_details: {
                order_id: orderData.orderId,
                gross_amount: orderData.totalAmount
            },
            customer_details: {
                first_name: orderData.customerName,
                email: orderData.customerEmail
            }
        });
        console.log('Midtrans createTransaction response:', response);
        return response;
    } catch (error) {
        console.error('Error in createTransaction:', error);
        throw error;
    }
};

export const isSnapScriptLoaded = () => {
    return typeof window.snap !== 'undefined';
};