import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import TestResult from './TestResult';
import './Test.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import LoadingSpinner from './LoadingSpinner';
import LogoLolosUjian from '../LogoLolosUjian.png';  // Add this import
import { Helmet } from 'react-helmet';  // Add this import

function Test() {
  const [test, setTest] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [timeLeft, setTimeLeft] = useState(null);
  const [testStartTime, setTestStartTime] = useState(null);
  const [testEnded, setTestEnded] = useState(false);
  const [testResults, setTestResults] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const { productId } = useParams();
  const navigate = useNavigate();
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState('');
  const [loading, setLoading] = useState(true);

  console.log("Test component rendered");

  const handleSubmit = useCallback(async () => {
    console.log("handleSubmit called");
    const endTime = new Date();
    const timeTaken = Math.floor((endTime - testStartTime) / 1000);

    let twkScore = 0;
    let tiuScore = 0;
    let tkpScore = 0;

    test.questions.forEach((question, index) => {
      const selectedAnswer = selectedAnswers[index];
      if (selectedAnswer) {
        switch (question.questionType) {
          case 'Tes Wawasan Kebangsaan':
            if (selectedAnswer === question.correctOption) {
              twkScore += 5;
            }
            break;
          case 'Tes Intelegensia Umum':
            if (selectedAnswer === question.correctOption) {
              tiuScore += 5;
            }
            break;
          case 'Tes Karakteristik Pribadi':
            const selectedOptionIndex = selectedAnswer.charCodeAt(0) - 65;
            tkpScore += question.options[selectedOptionIndex].point;
            break;
          default:
            break;
        }
      }
    });

    const testResults = {
      title: test.name,
      timeTaken,
      twkScore,
      tiuScore,
      tkpScore,
      totalQuestions: test.questions.length,
      userAnswers: selectedAnswers,
      completedAt: new Date().toISOString()
    };

    await setDoc(doc(db, 'userTests', `${userId}_${productId}`), testResults);

    setTestResults(testResults);
    setTestEnded(true);
    localStorage.removeItem(`testState_${productId}`);
    
    // Change this line to navigate to /result/ instead of /test-result/
    navigate(`/result/${productId}`);
  }, [test, selectedAnswers, testStartTime, productId, userId, navigate]);

  const fetchTest = useCallback(async () => {
    console.log("fetchTest called");
    if (!auth.currentUser) {
      console.error("No authenticated user found");
      return;
    }

    try {
      setLoading(true);  // Set loading to true when fetching starts
      console.log("Fetching test data for product:", productId);
      const docRef = doc(db, 'products', productId);
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {
        console.log("Test data retrieved successfully");
        const testData = docSnap.data();
        
        console.log("Checking if user has already taken the test");
        const userTestRef = doc(db, 'userTests', `${auth.currentUser.uid}_${productId}`);
        const userTestSnap = await getDoc(userTestRef);
        
        if (userTestSnap.exists()) {
          console.log("User has already taken this test, redirecting to results");
          navigate(`/test-result/${productId}`);
          return;
        }
        
        console.log("Setting up test data");
        setTest(testData);
        const savedState = JSON.parse(localStorage.getItem(`testState_${productId}`));
        if (savedState && savedState.timeLeft !== null && savedState.timeLeft > 0) {
          console.log("Restoring saved state", savedState);
          setTimeLeft(savedState.timeLeft);
          setTestStartTime(new Date(savedState.testStartTime));
          setCurrentQuestionIndex(savedState.currentQuestionIndex);
          setSelectedAnswers(savedState.selectedAnswers);
        } else {
          const initialTimeInSeconds = parseInt(testData.time) * 60;
          console.log("Setting initial time:", initialTimeInSeconds, "seconds");
          setTimeLeft(initialTimeInSeconds);
          setTestStartTime(new Date());
        }
      } else {
        console.error("No such document found for productId:", productId);
      }
    } catch (error) {
      console.error("Error fetching test data:", error);
      console.error("Error details:", error.code, error.message);
    } finally {
      setLoading(false);  // Set loading to false when fetching ends
    }
  }, [productId, navigate]);

  useEffect(() => {
    console.log("Test component mounted");
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        console.log("User authenticated:", user.uid);
        setUserId(user.uid);
        setUserName(user.displayName || user.email || 'Anonymous User');
        fetchTest();
      } else {
        console.log("No authenticated user, redirecting to login");
        navigate('/login');
      }
    });

    return () => {
      console.log("Test component unmounting");
      unsubscribe();
    };
  }, [navigate, fetchTest]);

  useEffect(() => {
    if (userId) {
      // Removed the fetchUserName function and the useEffect that calls it
    }
  }, [userId]);

  useEffect(() => {
    console.log("timeLeft changed:", timeLeft);
    if (timeLeft !== null && !isNaN(timeLeft) && timeLeft > 0) {
      console.log("Starting timer with", timeLeft, "seconds");
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => {
          if (prevTime <= 0) {
            clearInterval(timer);
            handleSubmit();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [timeLeft, handleSubmit]);

  useEffect(() => {
    if (test && !testEnded && timeLeft !== null) {
      console.log("Saving test state to localStorage");
      const stateToSave = {
        selectedAnswers,
        timeLeft,
        testStartTime: testStartTime?.toISOString(),
        currentQuestionIndex,
      };
      localStorage.setItem(`testState_${productId}`, JSON.stringify(stateToSave));
    }
  }, [test, selectedAnswers, timeLeft, testStartTime, currentQuestionIndex, testEnded, productId]);

  const handleClearAnswer = (questionIndex) => {
    setSelectedAnswers(prev => {
      const newAnswers = { ...prev };
      delete newAnswers[questionIndex];
      return newAnswers;
    });
  };

  const handleAnswer = (questionIndex, answer) => {
    setSelectedAnswers(prev => ({ ...prev, [questionIndex]: answer }));
  };

  const handleSubmitClick = () => {
    if (timeLeft > 0) {
      setShowConfirmation(true);
    } else {
      handleSubmit();
    }
  };

  const handleConfirmSubmit = () => {
    setShowConfirmation(false);
    handleSubmit();
  };

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  };

  const handleQuestionNavigation = (index) => {
    setCurrentQuestionIndex(index);
  };

  const isTimeWarning = timeLeft <= 60;

  console.log("Current state:", { test, timeLeft, testEnded });

  if (testEnded) {
    return <TestResult results={testResults} />;
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  if (!test || timeLeft === null) {
    return <LoadingSpinner />;
  }

  const currentQuestion = test.questions[currentQuestionIndex];
  const progress = ((currentQuestionIndex + 1) / test.questions.length) * 100;

  console.log("Rendering Test component, userName:", userName);

  return (
    <div className="test-container">
      <Helmet>
        <title>LolosUjian.com - Ujian</title>
        <meta name="description" content="Halaman ujian LolosUjian.com" />
      </Helmet>
      <div className="test-header">
        <div className="test-header-left">
          <img src={LogoLolosUjian} alt="Lolos Ujian Logo" className="logo" />
          <div className="test-info">
            <h1 className="test-title">{test?.name}</h1>
            <p className="test-taker">Peserta: {userName}</p>
          </div>
        </div>
        <div className={`timer ${isTimeWarning ? 'warning' : ''}`}>{formatTime(timeLeft)}</div>
      </div>
      <div className="progress-bar">
        <div className="progress" style={{ width: `${progress}%` }}></div>
      </div>
      <div className="test-content">
        <div className="question-container">
          <div className="question-header">
            <p className="question-label">Soal {currentQuestionIndex + 1}</p>
            <p className="question-type">{currentQuestion.questionType}</p>
          </div>
          {currentQuestion.imageUrl && (
            <img 
              src={currentQuestion.imageUrl} 
              alt="Question illustration" 
              className="question-image"
            />
          )}
          <p className="question-text">{currentQuestion.question}</p>
          <div className="options">
            {currentQuestion.options.map((option, index) => (
              <button
                key={index}
                onClick={() => handleAnswer(currentQuestionIndex, String.fromCharCode(65 + index))}
                className={`option ${selectedAnswers[currentQuestionIndex] === String.fromCharCode(65 + index) ? 'selected' : ''}`}
              >
                <span className="option-letter">{String.fromCharCode(65 + index)}</span>
                <span className="option-text">{option.text}</span>
              </button>
            ))}
          </div>
          <div className="question-actions">
            <div className="clear-button-container">
              {selectedAnswers[currentQuestionIndex] && (
                <button 
                  onClick={() => handleClearAnswer(currentQuestionIndex)}
                  className="clear-button"
                >
                  Clear
                </button>
              )}
            </div>
            <div className="navigation">
              <button 
                onClick={() => setCurrentQuestionIndex(prev => Math.max(0, prev - 1))}
                disabled={currentQuestionIndex === 0}
                className="nav-button"
                aria-label="Previous question"
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </button>
              <button 
                onClick={() => setCurrentQuestionIndex(prev => Math.min(test.questions.length - 1, prev + 1))}
                disabled={currentQuestionIndex === test.questions.length - 1}
                className="nav-button"
                aria-label="Next question"
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </button>
            </div>
          </div>
        </div>
        <div className="question-navigation">
          <div className="question-grid">
            {test.questions.map((_, index) => (
              <button
                key={index}
                onClick={() => handleQuestionNavigation(index)}
                className={`question-number-button 
                  ${currentQuestionIndex === index ? 'active' : ''} 
                  ${selectedAnswers[index] ? 'answered' : ''}
                `}
              >
                {index + 1}
              </button>
            ))}
          </div>
          <div className="submit-button-container">
            <button onClick={handleSubmitClick} className="submit-button">Akhiri Tes</button>
          </div>
        </div>
      </div>

      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-content">
            <p>Kamu masih punya {formatTime(timeLeft)} tersisa. Yakin ingin akhiri tes ini?</p>
            <div className="confirmation-buttons">
              <button onClick={handleConfirmSubmit} className="confirm-yes">Ya, akhiri</button>
              <button onClick={() => setShowConfirmation(false)} className="confirm-no">Batal</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Test;