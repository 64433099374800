import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate, Routes, Route } from 'react-router-dom';
import { getAuth, signOut } from 'firebase/auth';
import { collection, getDocs, doc, getDoc } from 'firebase/firestore';
import { useCart } from '../CartContext';
import { db } from '../firebase';
import Cart from './Cart';
import PurchasedProducts from './PurchasedProducts';
import Transactions from './Transactions';
import './Dashboard.css';
import LoadingSpinner from './LoadingSpinner';
import LogoLolosUjian from '../LogoLolosUjian.png';
import { Helmet } from 'react-helmet';

const getCategoryColor = (category) => {
  switch (category) {
    case 'CPNS':
      return '#E9724C';
    case 'IELTS':
      return '#C5283D';
    default:
      return '#4ECDC4';
  }
};

const formatPrice = (price) => {
  return new Intl.NumberFormat('id-ID', { 
    style: 'currency', 
    currency: 'IDR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0
  }).format(price).replace(/\s/g, '');
};

function DashboardContent() {
  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [showAlreadyPurchasedModal, setShowAlreadyPurchasedModal] = useState(false);
  const [filteredCategories, setFilteredCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const { cart, addToCart } = useCart();
  const navigate = useNavigate();
  const auth = getAuth();
  const user = auth.currentUser;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, 'products'));
        const productsData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        // Sort products by timestamp (newest first)
        const sortedProducts = productsData.sort((a, b) => {
          // Assuming there's a 'createdAt' field in each product document
          // If 'createdAt' doesn't exist, you'll need to add it to your products in Firestore
          return b.createdAt?.toMillis() - a.createdAt?.toMillis();
        });

        const categorizedProducts = sortedProducts.reduce((acc, product) => {
          if (!acc[product.category]) {
            acc[product.category] = [];
          }
          acc[product.category].push(product);
          return acc;
        }, {});
        setFilteredCategories(Object.entries(categorizedProducts).map(([name, products]) => ({ name, products })));
        setLoading(false);
      } catch (error) {
        console.error("Error fetching products:", error);
        setLoading(false);
      }
    };
    fetchProducts();
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  const handleAddToCart = async (product) => {
    if (product.category === 'IELTS') {
      // Do nothing for IELTS products
      return;
    }

    if (cart[product.id]) {
      setShowPopup(true);
    } else {
      // Check if the user has already purchased this product
      const purchasedProductsRef = doc(db, 'purchasedProducts', user.uid);
      const purchasedProductsSnap = await getDoc(purchasedProductsRef);
      
      if (purchasedProductsSnap.exists() && purchasedProductsSnap.data().products.includes(product.id)) {
        setShowAlreadyPurchasedModal(true);
      } else {
        setSelectedProduct(product);
        setShowModal(true);
      }
    }
  };

  const confirmAddToCart = () => {
    if (selectedProduct) {
      addToCart(selectedProduct.id);
      setShowModal(false);
      navigate('/dashboard/cart');
    } else {
      console.error('No product selected');
    }
  };

  return (
    <div className="dashboard-content">
      <h2 className="dashboard-title">Paket Ujian</h2>
      
      {/* Add the promotional banner */}
      <div className="promo-banner-container">
        <img 
          src="https://i.imgur.com/BjZ48Gr.png" 
          alt="Promotional Banner" 
          className="promo-banner-image"
        />
      </div>

      <div className="separator"></div>

      <main className="category-list">
        {filteredCategories.map(category => (
          <section key={category.name} className="category-section">
            <h2 className="category-title">{category.name}</h2>
            <div className="product-grid">
              {category.products.map(product => (
                <div key={product.id} className="product-card">
                  {product.isNew && <span className="new-tag">Baru!</span>}
                  <span 
                    className="category-tag" 
                    style={{backgroundColor: getCategoryColor(product.category)}}
                  >
                    {product.category}
                  </span>
                  <div className="product-card-content">
                    <h3>{product.name}</h3>
                    <p>Waktu pengerjaan: {product.time} menit</p>
                    <p>Total soal: {product.numberOfQuestions} butir</p>
                  </div>
                  {product.category === 'IELTS' ? (
                    <button 
                      style={{ backgroundColor: '#cccccc', color: '#666666', cursor: 'not-allowed' }}
                      disabled
                    >
                      Segera hadir
                    </button>
                  ) : (
                    <button onClick={() => handleAddToCart(product)} style={{ backgroundColor: '#255F85', color: '#ffffff' }}>
                      Mau tes ini
                    </button>
                  )}
                </div>
              ))}
            </div>
          </section>
        ))}
      </main>

      {showModal && selectedProduct && (
        <div className="modal">
          <div className="modal-content larger-modal">
            <button className="close-modal" onClick={() => setShowModal(false)}></button>
            <h2 className="modal-product-name">{selectedProduct.name}</h2>
            <div className="product-details">
              <div className="detail-box">
                <p className="inline-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="#255F85" width="16" height="16" className="time-icon">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                  </svg> 
                  Waktu: {selectedProduct.time} menit
                </p>
                <p className="inline-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#255F85" width="16" height="16" className="totalquestion-icon">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10" />
                  </svg>
                Soal: {selectedProduct.numberOfQuestions} butir
                </p>
                {selectedProduct.category === 'CPNS' && (
                  <>
                    <p className="inline-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="16" height="16" className="checkmark-icon"><circle cx="12" cy="12" r="12" fill="#255F85"/><path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z"/></svg> TIU (Tes Intelegensia Umum)</p>
                    <p className="inline-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="16" height="16" className="checkmark-icon"><circle cx="12" cy="12" r="12" fill="#255F85"/><path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z"/></svg> TKP (Tes Karakteristik Pribadi)</p>
                    <p className="inline-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="16" height="16" className="checkmark-icon"><circle cx="12" cy="12" r="12" fill="#255F85"/><path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z"/></svg> TWK (Tes Wawasan Kebangsaan)</p>
                  </>
                )}
                {selectedProduct.category === 'IELTS' && (
                  <>
                    <p className="inline-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="16" height="16" className="checkmark-icon"><circle cx="12" cy="12" r="12" fill="#255F85"/><path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z"/></svg> Listening</p>
                    <p className="inline-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="16" height="16" className="checkmark-icon"><circle cx="12" cy="12" r="12" fill="#255F85"/><path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z"/></svg> Reading</p>
                    <p className="inline-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="white" width="16" height="16" className="checkmark-icon"><circle cx="12" cy="12" r="12" fill="#255F85"/><path d="M9 16.2l-4.2-4.2-1.4 1.4 5.6 5.6 12-12-1.4-1.4z"/></svg> Writing</p>
                  </>
                )}
              </div>
            </div>
            <p className="price">{formatPrice(selectedProduct.price)}</p>
            <button 
              className="small-button full-width" 
              onClick={confirmAddToCart}
              style={{ backgroundColor: '#255F85', color: '#ffffff' }}
            >
              Tambah ke Keranjang &rarr;
            </button>
          </div>
        </div>
      )}

      {showPopup && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-modal" onClick={() => setShowPopup(false)}></button>
            <div className="modal-icon">🛒</div>
            <h2 className="modal-title">Sudah di Keranjang</h2>
            <p className="modal-message">Paket soal ini sudah ada di keranjangmu</p>
            <button 
              className="modal-action-button" 
              onClick={() => { setShowPopup(false); navigate('/dashboard/cart'); }}
              style={{ backgroundColor: '#255F85', color: '#ffffff' }}
            >
              Lihat Keranjang
            </button>
          </div>
        </div>
      )}

      {showAlreadyPurchasedModal && (
        <div className="modal">
          <div className="modal-content">
            <button className="close-modal" onClick={() => setShowAlreadyPurchasedModal(false)}></button>
            <div className="modal-icon">✅</div>
            <h2 className="modal-title">Paket Sudah Dimiliki</h2>
            <p className="modal-message">Kamu sudah memiliki paket ujian ini</p>
            <button 
              className="modal-action-button" 
              onClick={() => { setShowAlreadyPurchasedModal(false); navigate('/dashboard/purchased'); }}
              style={{ backgroundColor: '#255F85', color: '#ffffff' }}
            >
              Lihat Paket Ujianku
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

function Dashboard() {
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const auth = getAuth();
  const user = auth.currentUser;
  const navigate = useNavigate();
  const { cart } = useCart();

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleSignOut = () => {
    signOut(auth).then(() => {
      navigate('/');
    }).catch((error) => {
      console.error('Error signing out: ', error);
    });
  };

  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '?';
  };

  const cartItemCount = Object.values(cart).reduce((sum, quantity) => sum + quantity, 0);

  return (
    <div className="dashboard-container">
      <Helmet>
        <title>LolosUjian.com - Dashboard</title>
        <meta name="description" content="Dashboard pengguna LolosUjian.com" />
      </Helmet>
      <nav className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <button className="close-sidebar" onClick={() => setIsSidebarOpen(false)}>&times;</button>
        <div className="sidebar-content">
          <div className="sidebar-top">
            <Link to="/" className="logo-container">
              <img src={LogoLolosUjian} alt="LolosUjian.com Logo" className="sidebar-logo" />
            </Link>
            <div className="sidebar-separator"></div>
            <Link to="/dashboard" className={location.pathname === "/dashboard" ? "active" : ""}>
              <i className="fas fa-home"></i> Dashboard
            </Link>
            <Link to="/dashboard/purchased" className={location.pathname === "/dashboard/purchased" ? "active" : ""}>
              <i className="fas fa-file-alt"></i> Paket Ujianku
            </Link>
            <Link to="/dashboard/cart" className={location.pathname === "/dashboard/cart" ? "active" : ""}>
              <i className="fas fa-shopping-cart"></i> Keranjang
              {cartItemCount > 0 && <span className="cart-badge">{cartItemCount}</span>}
            </Link>
            <Link to="/dashboard/transactions" className={location.pathname === "/dashboard/transactions" ? "active" : ""}>
              <i className="fas fa-money-bill-wave"></i> Transaksi
            </Link>
          </div>
          <div className="sidebar-bottom">
            <div className="user-info">
              <div className="user-avatar">
                {getInitial(user?.displayName)}
              </div>
              <div className="user-details">
                <p className="user-name">{user?.displayName}</p>
                <p className="user-email">{user?.email}</p>
              </div>
            </div>
            <button className="sign-out" onClick={handleSignOut}>Sign Out</button>
          </div>
        </div>
      </nav>
      <div className="main-content">
        <button 
          className={`menu-toggle ${isSidebarOpen ? 'open' : ''}`} 
          onClick={toggleSidebar}
          aria-label="Toggle menu"
        >
          ☰
        </button>
        <Routes>
          <Route path="/" element={<DashboardContent />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/purchased" element={<PurchasedProducts />} />
          <Route path="/transactions" element={<Transactions />} />
        </Routes>
      </div>
    </div>
  );
}

export default Dashboard;