import axios from 'axios';

class CustomMidtransClient {
  constructor(options) {
    this.isProduction = options.isProduction || true;
    this.serverKey = options.serverKey;
    this.clientKey = options.clientKey;
    this.apiUrl = process.env.REACT_APP_API_URL || 'https://backend-lolosujian.onrender.com';
  }

  async createTransaction(parameter) {
    const apiUrl = `${this.apiUrl}/create-transaction`;

    try {
      console.log('Sending request to:', apiUrl);
      console.log('Request parameters:', parameter);

      const response = await axios.post(apiUrl, parameter);

      console.log('Response received:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in CustomMidtransClient:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
      throw error;
    }
  }

  async getTransactionStatus(orderId) {
    const apiUrl = `${this.apiUrl}/transaction-status/${orderId}`;

    try {
      console.log('Sending request to:', apiUrl);
      const response = await axios.get(apiUrl);
      console.log('Transaction status response:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error in getTransactionStatus:', error);
      if (error.response) {
        console.error('Error response:', error.response.data);
        console.error('Error status:', error.response.status);
      } else if (error.request) {
        console.error('No response received:', error.request);
      } else {
        console.error('Error setting up request:', error.message);
      }
      throw error;
    }
  }
}

export default CustomMidtransClient;