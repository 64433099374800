import React, { useState, useEffect, useCallback } from 'react';
import LoadingSpinner from './LoadingSpinner';
import { db, auth } from '../firebase';
import { collection, query, where, orderBy, getDocs, doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import './Transactions.css';
import axios from 'axios';
import { ArrowPathIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { sendConversionEvent } from '../services/MetaConversionAPI';

const checkPendingTransactions = async (transactions, user) => {
  console.log('Checking pending transactions');
  const pendingTransactions = transactions.filter(t => t.status === 'pending');
  console.log('Pending transactions:', pendingTransactions);
  const updatedTransactions = [];
  for (const transaction of pendingTransactions) {
    try {
      const response = await axios.get(`https://backend-lolosujian.onrender.com/transaction-status/${transaction.id}`);
      console.log(`Status check response for ${transaction.id}:`, response.data);
      
      const updatedTransaction = {
        ...transaction,
        midtransStatus: response.data.transaction_status,
        expiryTime: response.data.expiry_time
      };
      
      if (response.data.transaction_status === 'settlement') {
        // Update transaction status in Firestore
        await updateDoc(doc(db, 'orders', transaction.id), { 
          status: 'paid',
          paymentDetails: response.data
        });

        const eventData = {
          event_name: 'Purchase',
          event_time: Math.floor(Date.now() / 1000), // Current time in seconds
          event_source_url: window.location.href, // Current URL
          action_source: 'website',
          currency: 'IDR', // Replace with the actual currency
          value: transaction.totalAmount, // Total purchase value
          user_data: {
            em: user.email,
            fn: user.displayName,
            client_user_agent: navigator.userAgent, // Client user agent
          },
        };
  
        // Send conversion event
        await sendConversionEvent(eventData);

        // Add purchased products to user's purchased items
        const purchasedProductsRef = doc(db, 'purchasedProducts', user.uid);
        const purchasedProductsSnap = await getDoc(purchasedProductsRef);
        
        if (purchasedProductsSnap.exists()) {
          await updateDoc(purchasedProductsRef, {
            products: [...new Set([...purchasedProductsSnap.data().products, ...Object.keys(transaction.products)])]
          });
        } else {
          await setDoc(purchasedProductsRef, {
            products: Object.keys(transaction.products)
          });
        }

        console.log(`Updated purchased products for user ${user.uid}`);
        updatedTransaction.status = 'paid';
      }
      
      updatedTransactions.push(updatedTransaction);
    } catch (error) {
      if (error.response && error.response.status === 404) {
        console.log(`Transaction ${transaction.id} not found in Midtrans. It may have been marked as expired.`);
      } else {
        console.error('Error checking transaction status:', error);
      }
      updatedTransactions.push(transaction);
    }
  }
  return updatedTransactions;
};

function Transactions() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [productDetails, setProductDetails] = useState({});
  const user = auth.currentUser;
  const transactionsPerPage = 5;
  const navigate = useNavigate();

  const fetchTransactions = useCallback(async () => {
    if (!user) {
      setLoading(false);
      return;
    }

    setLoading(true);
    const q = query(
      collection(db, 'orders'),
      where('userId', '==', user.uid),
      orderBy('createdAt', 'desc')
    );

    try {
      const querySnapshot = await getDocs(q);
      let transactionsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate().toLocaleString()
      }));

      console.log('Transactions data:', transactionsData);
      
      const updatedPendingTransactions = await checkPendingTransactions(transactionsData, user);
      
      transactionsData = transactionsData.map(t => 
        updatedPendingTransactions.find(ut => ut.id === t.id) || t
      );

      setTransactions(transactionsData);
      setTotalPages(Math.ceil(transactionsData.length / transactionsPerPage));
      
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setError(error.message);
      setLoading(false);
    }
  }, [user]);

  useEffect(() => {
    fetchTransactions();
  }, [fetchTransactions]);

  useEffect(() => {
    const fetchProductDetails = async () => {
      const productIds = new Set(transactions.flatMap(t => Object.keys(t.products || {})));
      const details = {};
      for (const id of productIds) {
        const productDoc = await getDoc(doc(db, 'products', id));
        if (productDoc.exists()) {
          details[id] = productDoc.data().name;
        }
      }
      setProductDetails(details);
    };

    if (transactions.length > 0) {
      fetchProductDetails();
    }
  }, [transactions]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleRefresh = () => {
    fetchTransactions();
  };

  const formatPrice = (price) => {
    return new Intl.NumberFormat('id-ID', { 
      style: 'currency', 
      currency: 'IDR',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(price).replace(/\s/g, '');
  };

  const renderTransactionStatus = (status) => {
    return status || 'Unknown';
  };

  const renderProducts = (products) => {
    if (!products || typeof products !== 'object') {
      return <li>No product details available</li>;
    }

    return Object.entries(products).map(([productId]) => (
      <li key={productId} className="product-pill">
        {productDetails[productId] || 'Loading...'}
      </li>
    ));
  };

  const renderExpiryTime = (transaction) => {
    if (transaction.status !== 'pending' || !transaction.expiryTime) {
      return null;
    }

    const expiryTime = new Date(transaction.expiryTime);
    const now = new Date();
    const timeLeft = expiryTime - now;

    if (timeLeft <= 0) {
      return <p className="expiry-time expired">Expired</p>;
    }

    const hours = Math.floor(timeLeft / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));

    return (
      <p className="expiry-time">
        Expires in: {hours}h {minutes}m
      </p>
    );
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  if (error) {
    return <div className="transactions-error">Error: {error}</div>;
  }

  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstTransaction, indexOfLastTransaction);

  return (
    <div className="transactions">
      <Helmet>
        <title>LolosUjian.com - Riwayat Transaksi</title>
        <meta name="description" content="Riwayat transaksi pengguna LolosUjian.com" />
      </Helmet>
      <div className="transactions-header">
        <h2><strong>Riwayat Transaksi</strong></h2>
        <button onClick={handleRefresh} className="refresh-button" aria-label="Refresh transactions">
          <ArrowPathIcon className="refresh-icon" />
        </button>
      </div>
      {transactions.length === 0 ? (
        <div className="empty-transactions">
          <p className="empty-transactions-message">Kamu belum memiliki transaksi</p>
          <div className="empty-transactions-icon animated">💸</div>
          <button className="back-to-dashboard" onClick={() => navigate('/dashboard')}>
            Kembali ke Dashboard
          </button>
        </div>
      ) : (
        <>
          <div className="transaction-list">
            {currentTransactions.map((transaction) => (
              <div key={transaction.id} className="transaction-card">
                <div className="transaction-header">
                  <span className="transaction-id">Order ID: {transaction.id}</span>
                  <span className={`transaction-status status-${transaction.status}`}>
                    {renderTransactionStatus(transaction.status)}
                  </span>
                </div>
                <div className="transaction-body">
                  <p>Date: {transaction.createdAt}</p>
                  <p>Total: {formatPrice(transaction.totalAmount)}</p>
                  {renderExpiryTime(transaction)}
                </div>
                <div className="transaction-products">
                  <h4>Products:</h4>
                  <ul className="product-list">
                    {renderProducts(transaction.products)}
                  </ul>
                </div>
              </div>
            ))}
          </div>
          <div className="pagination">
            <button 
              onClick={() => handlePageChange(currentPage - 1)} 
              disabled={currentPage === 1}
              aria-label="Previous page"
            >
              &#8592; {/* Left arrow */}
            </button>
            <span>{currentPage} of {totalPages}</span>
            <button 
              onClick={() => handlePageChange(currentPage + 1)} 
              disabled={currentPage === totalPages}
              aria-label="Next page"
            >
              &#8594; {/* Right arrow */}
            </button>
          </div>
        </>
      )}
    </div>
  );
}

export default Transactions;