import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './firebase';
import LoadingSpinner from './components/LoadingSpinner';  // Add this import
import Home from './components/Home';
import Footer from './components/Footer';
import Login from './components/Login';
import Register from './components/Register';
import Dashboard from './components/Dashboard';
import Cart from './components/Cart';
import Admin from './components/Admin';
import ScrollToTop from './components/ScrollToTop';
import { CartProvider } from './CartContext';
import './App.css';
import Test from './components/Test';
import TestResult from './components/TestResult';
import Explanations from './components/Explanations';  // Add this import
import ForgotPassword from './components/ForgotPassword';
import Disclaimer from './components/Disclaimer';
import TermsAndConditions from './components/TermsAndConditions';
import LandingCPNS from './components/LandingCPNS'; // Import the new component

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <LoadingSpinner />;  // Replace the div with LoadingSpinner
  }

  return (
    <Router>
      <CartProvider>
        <AppContent user={user} />
      </CartProvider>
    </Router>
  );
}

function AppContent({ user }) {
  const location = useLocation();

  const isAdmin = (user) => {
    return user && user.email === 'admin@example.com';
  };

  const showFooter = !['/dashboard', '/test', '/login', '/register', '/explanations', '/result', '/landing-cpns'].some(path => location.pathname.startsWith(path));

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/landing-cpns" element={<LandingCPNS />} /> {/* Add this route */}
        <Route 
          path="/login" 
          element={user ? <Navigate to="/dashboard" /> : <Login />} 
        />
        <Route 
          path="/register" 
          element={user ? <Navigate to="/dashboard" /> : <Register />} 
        />
        <Route 
          path="/dashboard/*" 
          element={user ? <Dashboard /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/admin" 
          element={
            user 
              ? isAdmin(user) 
                ? <Admin /> 
                : <Navigate to="/dashboard" />
              : <Navigate to="/login" />
          } 
        />
        <Route 
          path="/test/:productId" 
          element={user ? <Test /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/result/:productId" 
          element={user ? <TestResult /> : <Navigate to="/login" />} 
        />
        <Route 
          path="/explanations/:productId" 
          element={user ? <Explanations /> : <Navigate to="/login" />} 
        />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/terms" element={<TermsAndConditions />} />
      </Routes>
      {showFooter && <Footer />}
    </div>
  );
}

export default App;
