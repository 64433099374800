import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, deleteDoc, doc, Timestamp, query, where } from 'firebase/firestore';
import { formatPrice } from '../utils/formatters';
import './Admin.css';

function Admin() {
  const [products, setProducts] = useState([]);
  const [newProduct, setNewProduct] = useState({ 
    name: '', 
    price: '', 
    category: '',
    time: '',
    numberOfQuestions: '',
    questions: [],
    createdAt: new Date().toISOString().split('T')[0], // Set default to today's date
    isNew: false // Add this line
  });
  const [editingProduct, setEditingProduct] = useState(null);
  const [newQuestion, setNewQuestion] = useState({ 
    question: '', 
    imageUrl: '', // Add this line
    options: [
      { text: '', point: 1 },
      { text: '', point: 1 },
      { text: '', point: 1 },
      { text: '', point: 1 },
      { text: '', point: 1 }
    ], 
    correctOption: '',
    questionType: '',
    explanation: ''
  });
  const [vouchers, setVouchers] = useState([]);
  const [newVoucher, setNewVoucher] = useState({
    code: '',
    discountType: 'percentage',
    discountValue: '',
    usageLimit: '',
    minTransaction: '', // Add this line
  });

  const [activeTab, setActiveTab] = useState('products');

  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('All');

  const [currentPage, setCurrentPage] = useState(1);
  const productsPerPage = 5;

  useEffect(() => {
    fetchProducts();
    fetchVouchers();
  }, []);

  const fetchProducts = async () => {
    const querySnapshot = await getDocs(collection(db, 'products'));
    const productsData = querySnapshot.docs.map(doc => ({ 
      id: doc.id, 
      ...doc.data(),
      createdAt: doc.data().createdAt?.toDate().toISOString().split('T')[0] || ''
    }));
    setProducts(productsData);

    // Extract unique categories
    const uniqueCategories = [...new Set(productsData.map(product => product.category))];
    setCategories(['All', ...uniqueCategories]);
  };

  const handleAddProduct = async (e) => {
    e.preventDefault();
    const productToAdd = {
      ...newProduct,
      createdAt: Timestamp.fromDate(new Date(newProduct.createdAt))
    };
    await addDoc(collection(db, 'products'), productToAdd);
    setNewProduct({ 
      name: '', 
      price: '', 
      category: '', 
      time: '', 
      numberOfQuestions: '', 
      questions: [],
      createdAt: new Date().toISOString().split('T')[0],
      isNew: false // Add this line
    });
    fetchProducts();
  };

  const handleUpdateProduct = async (e) => {
    e.preventDefault();
    const productToUpdate = {
      ...editingProduct,
      createdAt: Timestamp.fromDate(new Date(editingProduct.createdAt)),
      isNew: editingProduct.isNew // Add this line
    };
    await updateDoc(doc(db, 'products', editingProduct.id), productToUpdate);
    setEditingProduct(null);
    fetchProducts();
  };

  const handleDeleteProduct = async (id) => {
    await deleteDoc(doc(db, 'products', id));
    fetchProducts();
  };

  const handleAddQuestion = () => {
    if (editingProduct) {
      setEditingProduct({
        ...editingProduct,
        questions: [...editingProduct.questions, newQuestion]
      });
    } else {
      setNewProduct({
        ...newProduct,
        questions: [...newProduct.questions, newQuestion]
      });
    }
    setNewQuestion({ 
      question: '', 
      imageUrl: '', // Add this line
      options: [
        { text: '', point: 1 },
        { text: '', point: 1 },
        { text: '', point: 1 },
        { text: '', point: 1 },
        { text: '', point: 1 }
      ], 
      correctOption: '', 
      questionType: '', 
      explanation: '' 
    });
  };

  const handleEditQuestion = (index, updatedQuestion) => {
    const updatedQuestions = [...editingProduct.questions];
    updatedQuestions[index] = updatedQuestion;
    setEditingProduct({
      ...editingProduct,
      questions: updatedQuestions
    });
  };

  const handleDeleteQuestion = (index) => {
    const updatedQuestions = editingProduct.questions.filter((_, i) => i !== index);
    setEditingProduct({
      ...editingProduct,
      questions: updatedQuestions
    });
  };

  const getCPNSQuestionTypes = () => [
    'Tes Wawasan Kebangsaan',
    'Tes Intelegensia Umum',
    'Tes Karakteristik Pribadi'
  ];

  const renderQuestionTypeInput = (product, question, onChange) => {
    if (product.category.toLowerCase() === 'cpns') {
      return (
        <select
          value={question.questionType}
          onChange={(e) => onChange({...question, questionType: e.target.value})}
        >
          <option value="">Select Question Type</option>
          {getCPNSQuestionTypes().map((type, index) => (
            <option key={index} value={type}>{type}</option>
          ))}
        </select>
      );
    } else {
      return (
        <input
          type="text"
          placeholder="Question Type"
          value={question.questionType}
          onChange={(e) => onChange({...question, questionType: e.target.value})}
        />
      );
    }
  };

  const renderOptionInputs = (question, onChange) => {
    if (question.questionType === 'Tes Karakteristik Pribadi') {
      return question.options.map((option, index) => (
        <div key={index} className="option-input">
          <input
            type="text"
            placeholder={`Option ${String.fromCharCode(65 + index)}`}
            value={option.text}
            onChange={(e) => {
              const newOptions = [...question.options];
              newOptions[index] = { ...newOptions[index], text: e.target.value };
              onChange({ ...question, options: newOptions });
            }}
          />
          <input
            type="number"
            min="1"
            max="5"
            value={option.point}
            onChange={(e) => {
              const newOptions = [...question.options];
              newOptions[index] = { ...newOptions[index], point: parseInt(e.target.value) };
              onChange({ ...question, options: newOptions });
            }}
          />
        </div>
      ));
    } else {
      return question.options.map((option, index) => (
        <input
          key={index}
          type="text"
          placeholder={`Option ${String.fromCharCode(65 + index)}`}
          value={option.text}
          onChange={(e) => {
            const newOptions = [...question.options];
            newOptions[index] = { ...newOptions[index], text: e.target.value };
            onChange({ ...question, options: newOptions });
          }}
        />
      ));
    }
  };

  const renderCorrectOptionInput = (question, onChange) => {
    if (question.questionType !== 'Tes Karakteristik Pribadi') {
      return (
        <select
          value={question.correctOption}
          onChange={(e) => onChange({ ...question, correctOption: e.target.value })}
        >
          <option value="">Select Correct Option</option>
          {question.options.map((_, index) => (
            <option key={index} value={String.fromCharCode(65 + index)}>
              Option {String.fromCharCode(65 + index)}
            </option>
          ))}
        </select>
      );
    }
    return null;
  };

  const fetchVouchers = async () => {
    const querySnapshot = await getDocs(collection(db, 'vouchers'));
    setVouchers(querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
  };

  const handleAddVoucher = async (e) => {
    e.preventDefault();
    await addDoc(collection(db, 'vouchers'), {
      ...newVoucher,
      minTransaction: Number(newVoucher.minTransaction), // Convert to number
    });
    setNewVoucher({
      code: '',
      discountType: 'percentage',
      discountValue: '',
      usageLimit: '',
      minTransaction: '', // Add this line
    });
    fetchVouchers();
  };

  const handleDeleteVoucher = async (id) => {
    await deleteDoc(doc(db, 'vouchers', id));
    fetchVouchers();
  };

  const filteredProducts = selectedCategory === 'All' 
    ? products 
    : products.filter(product => product.category === selectedCategory);

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="admin-dashboard">
      <h2>Admin Dashboard</h2>
      
      <div className="admin-tabs">
        <button 
          className={`tab-button ${activeTab === 'products' ? 'active' : ''}`} 
          onClick={() => setActiveTab('products')}
        >
          Products
        </button>
        <button 
          className={`tab-button ${activeTab === 'vouchers' ? 'active' : ''}`} 
          onClick={() => setActiveTab('vouchers')}
        >
          Vouchers
        </button>
      </div>

      {activeTab === 'products' && (
        <div className="admin-section">
          <h3>Add New Product</h3>
          <form onSubmit={handleAddProduct} className="admin-form">
            <input
              type="text"
              placeholder="Product Name"
              value={newProduct.name}
              onChange={(e) => setNewProduct({...newProduct, name: e.target.value})}
            />
            <input
              type="number"
              placeholder="Price"
              value={newProduct.price}
              onChange={(e) => setNewProduct({...newProduct, price: e.target.value})}
            />
            <input
              type="text"
              placeholder="Category"
              value={newProduct.category}
              onChange={(e) => setNewProduct({...newProduct, category: e.target.value})}
            />
            <input
              type="text"
              placeholder="Time (e.g., 2 hours)"
              value={newProduct.time}
              onChange={(e) => setNewProduct({...newProduct, time: e.target.value})}
            />
            <input
              type="number"
              placeholder="Number of Questions"
              value={newProduct.numberOfQuestions}
              onChange={(e) => setNewProduct({...newProduct, numberOfQuestions: e.target.value})}
            />
            <input
              type="date"
              value={newProduct.createdAt}
              onChange={(e) => setNewProduct({...newProduct, createdAt: e.target.value})}
            />
            <label>
              <input
                type="checkbox"
                checked={newProduct.isNew}
                onChange={(e) => setNewProduct({...newProduct, isNew: e.target.checked})}
              />
              Mark as New Product
            </label>
            
            <div className="question-section">
              <h4>Add Questions</h4>
              <input
                type="text"
                placeholder="Question"
                value={newQuestion.question}
                onChange={(e) => setNewQuestion({...newQuestion, question: e.target.value})}
              />
              <input
                type="text"
                placeholder="Image URL (optional)"
                value={newQuestion.imageUrl}
                onChange={(e) => setNewQuestion({...newQuestion, imageUrl: e.target.value})}
              />
              {renderQuestionTypeInput(
                newProduct,
                newQuestion,
                (updatedQuestion) => setNewQuestion(updatedQuestion)
              )}
              {renderOptionInputs(newQuestion, setNewQuestion)}
              {renderCorrectOptionInput(newQuestion, setNewQuestion)}
              <textarea
                placeholder="Explanation"
                value={newQuestion.explanation}
                onChange={(e) => setNewQuestion({...newQuestion, explanation: e.target.value})}
              />
              <button type="button" onClick={handleAddQuestion} className="secondary-button">Add Question</button>
            </div>
            
            <button type="submit" className="primary-button">Add Product</button>
          </form>

          <h3>Product List</h3>
          <div className="category-filter">
            <label htmlFor="category-select">Filter by Category:</label>
            <select 
              id="category-select"
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              {categories.map(category => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
          </div>
          <div className="product-list">
            {currentProducts.map(product => (
              <div key={product.id} className="product-item">
                {editingProduct && editingProduct.id === product.id ? (
                  <form onSubmit={handleUpdateProduct} className="admin-form">
                    <input
                      type="text"
                      value={editingProduct.name}
                      onChange={(e) => setEditingProduct({...editingProduct, name: e.target.value})}
                    />
                    <input
                      type="number"
                      value={editingProduct.price}
                      onChange={(e) => setEditingProduct({...editingProduct, price: e.target.value})}
                    />
                    <input
                      type="text"
                      value={editingProduct.category}
                      onChange={(e) => setEditingProduct({...editingProduct, category: e.target.value})}
                    />
                    <input
                      type="text"
                      value={editingProduct.time}
                      onChange={(e) => setEditingProduct({...editingProduct, time: e.target.value})}
                    />
                    <input
                      type="number"
                      value={editingProduct.numberOfQuestions}
                      onChange={(e) => setEditingProduct({...editingProduct, numberOfQuestions: e.target.value})}
                    />
                    <input
                      type="date"
                      value={editingProduct.createdAt}
                      onChange={(e) => setEditingProduct({...editingProduct, createdAt: e.target.value})}
                    />
                    <label>
                      <input
                        type="checkbox"
                        checked={editingProduct.isNew}
                        onChange={(e) => setEditingProduct({...editingProduct, isNew: e.target.checked})}
                      />
                      Mark as New Product
                    </label>
                    <h4>Questions</h4>
                    {editingProduct.questions.map((question, index) => (
                      <div key={index} className="question-item">
                        <input
                          type="text"
                          value={question.question}
                          onChange={(e) => handleEditQuestion(index, {...question, question: e.target.value})}
                        />
                        <input
                          type="text"
                          placeholder="Image URL (optional)"
                          value={question.imageUrl}
                          onChange={(e) => handleEditQuestion(index, {...question, imageUrl: e.target.value})}
                        />
                        {renderQuestionTypeInput(
                          editingProduct,
                          question,
                          (updatedQuestion) => handleEditQuestion(index, updatedQuestion)
                        )}
                        {renderOptionInputs(question, (updatedQuestion) => handleEditQuestion(index, updatedQuestion))}
                        {renderCorrectOptionInput(question, (updatedQuestion) => handleEditQuestion(index, updatedQuestion))}
                        <textarea
                          placeholder="Explanation"
                          value={question.explanation}
                          onChange={(e) => handleEditQuestion(index, {...question, explanation: e.target.value})}
                        />
                        <button type="button" onClick={() => handleDeleteQuestion(index)} className="delete-button">Delete Question</button>
                      </div>
                    ))}
                    <button type="button" onClick={handleAddQuestion} className="secondary-button">Add New Question</button>
                    <button type="submit" className="primary-button">Save</button>
                    <button type="button" onClick={() => setEditingProduct(null)} className="secondary-button">Cancel</button>
                  </form>
                ) : (
                  <>
                    <p><strong>{product.name}</strong> - {formatPrice(product.price)} - {product.category} - {product.time} - {product.numberOfQuestions} questions - Created: {product.createdAt}
                      {product.isNew && <span className="new-tag"> - New!</span>}
                    </p>
                    <div className="button-group">
                      <button onClick={() => setEditingProduct(product)} className="edit-button">Edit</button>
                      <button onClick={() => handleDeleteProduct(product.id)} className="delete-button">Delete</button>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
          <div className="pagination">
            {Array.from({ length: Math.ceil(filteredProducts.length / productsPerPage) }, (_, i) => (
              <button key={i} onClick={() => paginate(i + 1)} className={currentPage === i + 1 ? 'active' : ''}>
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      )}

      {activeTab === 'vouchers' && (
        <div className="admin-section">
          <h3>Add New Voucher</h3>
          <form onSubmit={handleAddVoucher} className="admin-form">
            <input
              type="text"
              placeholder="Voucher Code"
              value={newVoucher.code}
              onChange={(e) => setNewVoucher({...newVoucher, code: e.target.value})}
            />
            <select
              value={newVoucher.discountType}
              onChange={(e) => setNewVoucher({...newVoucher, discountType: e.target.value})}
            >
              <option value="percentage">Percentage</option>
              <option value="fixed">Fixed Amount</option>
            </select>
            <input
              type="number"
              placeholder="Discount Value"
              value={newVoucher.discountValue}
              onChange={(e) => setNewVoucher({...newVoucher, discountValue: e.target.value})}
            />
            <input
              type="number"
              placeholder="Usage Limit"
              value={newVoucher.usageLimit}
              onChange={(e) => setNewVoucher({...newVoucher, usageLimit: e.target.value})}
            />
            <input
              type="number"
              placeholder="Minimum Transaction Amount"
              value={newVoucher.minTransaction}
              onChange={(e) => setNewVoucher({...newVoucher, minTransaction: e.target.value})}
            />
            <button type="submit" className="primary-button">Add Voucher</button>
          </form>

          <h3>Voucher List</h3>
          <div className="voucher-list">
            {vouchers.map(voucher => (
              <div key={voucher.id} className="voucher-item">
                <p>
                  <strong>{voucher.code}</strong> - 
                  {voucher.discountType === 'percentage' 
                    ? `${voucher.discountValue}%` 
                    : `${formatPrice(Number(voucher.discountValue))} off`} - 
                  Usage Limit: {voucher.usageLimit} -
                  Min Transaction: {formatPrice(Number(voucher.minTransaction))}
                </p>
                <button onClick={() => handleDeleteVoucher(voucher.id)} className="delete-button">Delete</button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default Admin;