import React from 'react';
import { Helmet } from 'react-helmet';
import './LegalPages.css';

function TermsAndConditions() {
  const currentDate = new Date().toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });

  return (
    <div className="legal-page">
      <Helmet>
        <title>Terms and Conditions - LolosUjian.com</title>
      </Helmet>
      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-bold mb-6 text-center">Terms and Conditions</h1>
        <div className="prose max-w-none">
          <p>Last updated: {currentDate}</p>
          
          <h2>1. Acceptance of Terms</h2>
          <p>By accessing and using LolosUjian.com, you accept and agree to be bound by the terms and provision of this agreement.</p>
          
          <h2>2. Use of the Service</h2>
          <p>You agree to use LolosUjian.com only for purposes permitted by these Terms and any applicable law, regulation, or generally accepted practices or guidelines in the relevant jurisdictions.</p>
          
          <h2>3. User Accounts</h2>
          <p>To access certain features of the Service, you may be required to create an account. You are responsible for maintaining the confidentiality of your account and password.</p>
          
          <h2>4. Content</h2>
          <p>Our Service allows you to post, link, store, share and otherwise make available certain information, text, graphics, or other material. You are responsible for the content that you post to the Service, including its legality, reliability, and appropriateness.</p>
          
          <h2>5. Intellectual Property</h2>
          <p>The Service and its original content, features, and functionality are and will remain the exclusive property of LolosUjian.com and its licensors.</p>
          
          <h2>6. Termination</h2>
          <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
          
          <h2>7. Limitation of Liability</h2>
          <p>In no event shall LolosUjian.com, nor its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from your access to or use of or inability to access or use the Service.</p>
          
          <h2>8. Changes</h2>
          <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. What constitutes a material change will be determined at our sole discretion.</p>
          
          <h2>9. Contact Us</h2>
          <p>If you have any questions about these Terms, please contact us at support@lolosujian.com.</p>
        </div>
        <hr className="my-8 border-t-2 border-gray-200" />
      </div>
    </div>
  );
}

export default TermsAndConditions;