import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import LoadingSpinner from './LoadingSpinner';
import { getAuth } from 'firebase/auth';
import { db } from '../firebase';
import { doc, getDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { useLocation, useNavigate } from 'react-router-dom';
import './PurchasedProducts.css';

const getCategoryColor = (category) => {
  switch (category) {
    case 'CPNS':
      return '#E9724C';
    case 'IELTS':
      return '#C5283D';
    default:
      return '#4ECDC4'; // Default color for other categories
  }
};

function PurchasedProducts() {
  const [purchasedProducts, setPurchasedProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const auth = getAuth();
  const user = auth.currentUser;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    if (user) {
      fetchPurchasedProductsAndResults();
    }
  }, [user]);

  const fetchPurchasedProductsAndResults = async () => {
    if (user) {
      setLoading(true);
      try {
        const purchasedProductsRef = doc(db, 'purchasedProducts', user.uid);
        const purchasedProductsSnap = await getDoc(purchasedProductsRef);

        if (purchasedProductsSnap.exists()) {
          const productIds = purchasedProductsSnap.data().products;
          const productsWithResults = await Promise.all(
            productIds.map(async (productId) => {
              const productDoc = await getDoc(doc(db, 'products', productId));
              const userTestDoc = await getDoc(doc(db, 'userTests', `${user.uid}_${productId}`));
              
              if (productDoc.exists()) {
                return {
                  id: productId,
                  ...productDoc.data(),
                  testTaken: userTestDoc.exists(),
                  testResult: userTestDoc.exists() ? userTestDoc.data() : null
                };
              }
              return null;
            })
          );

          const filteredProducts = productsWithResults.filter(product => product !== null);
          setPurchasedProducts(filteredProducts);
        } else {
          setPurchasedProducts([]);
        }
      } catch (error) {
        console.error("Error fetching purchased products and results:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleStartTest = (productId) => {
    setSelectedProductId(productId);
    setShowConfirmation(true);
  };

  const handleViewResults = (productId) => {
    navigate(`/result/${productId}`);
  };

  const confirmStartTest = () => {
    setShowConfirmation(false);
    navigate(`/test/${selectedProductId}`);
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const categorizedProducts = purchasedProducts.reduce((acc, product) => {
    if (!acc[product.category]) {
      acc[product.category] = [];
    }
    acc[product.category].push(product);
    return acc;
  }, {});

  return (
    <div className="purchased-products">
      <Helmet>
        <title>LolosUjian.com - Paket Ujianku</title>
        <meta name="description" content="Daftar paket ujian yang telah dibeli di LolosUjian.com" />
      </Helmet>
      <h2 className="purchased-title"><strong>Paket Ujianku</strong></h2>
      {purchasedProducts.length === 0 ? (
        <div className="empty-purchased">
          <p className="empty-purchased-message">Kamu belum memiliki paket ujian</p>
          <div className="empty-purchased-icon animated">📦</div>
          <button className="back-to-dashboard" onClick={() => navigate('/dashboard')}>
            Kembali ke Dashboard
          </button>
        </div>
      ) : (
        <main className="category-list">
          {Object.entries(categorizedProducts).map(([category, products]) => (
            <section key={category} className="category-section">
              <h3 className="category-title">{category}</h3>
              <div className="product-grid">
                {products.map((product) => (
                  <div key={product.id} className="product-card">
                    <span 
                      className="category-tag" 
                      style={{backgroundColor: getCategoryColor(product.category)}}
                    >
                      {product.category}
                    </span>
                    <div className="product-card-content">
                      <h3>{product.name}</h3>
                      <p>Waktu pengerjaan: {product.time} menit</p>
                      <p>Total soal: {product.numberOfQuestions} butir</p>
                    </div>
                    {product.testTaken ? (
                      <button 
                        onClick={() => handleViewResults(product.id)}
                        className="view-results-button"
                      >
                        Lihat Hasil & Pembahasan
                      </button>
                    ) : (
                      <button onClick={() => handleStartTest(product.id)}>Mulai Tes</button>
                    )}
                  </div>
                ))}
              </div>
            </section>
          ))}
        </main>
      )}

      {showConfirmation && (
        <div className="confirmation-popup">
          <div className="confirmation-content">
            <p>Kamu yakin ingin mulai tes ini? Setelah dimulai, kamu tidak bisa pause atau restart tes ini.</p>
            <div className="confirmation-buttons">
              <button onClick={confirmStartTest} className="confirm-yes">Ya, mulai tes</button>
              <button onClick={() => setShowConfirmation(false)} className="confirm-no">Batal</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default PurchasedProducts;