import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { db, auth } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './Explanations.css';
import LogoLolosUjian from '../LogoLolosUjian.png';
import LoadingSpinner from './LoadingSpinner';  // Add this import
import { Helmet } from 'react-helmet';  // Add this import

function Explanations() {
  const [test, setTest] = useState(null);
  const [userAnswers, setUserAnswers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const navigate = useNavigate();
  const { productId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const user = auth.currentUser;
        if (!user) {
          navigate('/login');
          return;
        }

        const testRef = doc(db, 'products', productId);
        const testSnap = await getDoc(testRef);
        
        const userTestRef = doc(db, 'userTests', `${user.uid}_${productId}`);
        const userTestSnap = await getDoc(userTestRef);
        
        if (testSnap.exists() && userTestSnap.exists()) {
          setTest(testSnap.data());
          setUserAnswers(userTestSnap.data().userAnswers);
        } else {
          setError("Test data or user answers not found.");
          navigate('/dashboard/purchased');
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("An error occurred while fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [navigate, productId]);

  const handleQuestionChange = (index) => {
    setCurrentQuestionIndex(index);
  };

  const renderOption = (option, optionIndex, isCorrect, isUserSelected) => {
    const optionLetter = String.fromCharCode(65 + optionIndex);
    const isTKP = currentQuestion.questionType === 'Tes Karakteristik Pribadi';
    const isHighestPoint = isTKP && option.point === 5;

    return (
      <div 
        key={optionIndex} 
        className={`option ${isCorrect && !isTKP ? 'correct' : ''} ${isUserSelected ? 'user-selected' : ''} ${isHighestPoint ? 'highest-point' : ''}`}
      >
        <span className="option-letter">{optionLetter}</span>
        <span className="option-text">{option.text}</span>
        {isTKP ? (
          <span className="option-point">{option.point} poin</span>
        ) : (
          <>
            {isCorrect && <span className="correct-marker"><i className="fas fa-check"></i></span>}
            {isUserSelected && !isCorrect && <span className="incorrect-marker"><i className="fas fa-times"></i></span>}
          </>
        )}
      </div>
    );
  };

  if (loading) return <LoadingSpinner />;  // Replace this line
  if (error) return <div className="error">{error}</div>;
  if (!test || !userAnswers) return <div className="error">No data available</div>;

  const currentQuestion = test.questions[currentQuestionIndex];

  return (
    <div className="explanations">
      <Helmet>
        <title>LolosUjian.com - Pembahasan Soal</title>
        <meta name="description" content="Pembahasan soal ujian LolosUjian.com" />
      </Helmet>
      <img src={LogoLolosUjian} alt="Lolos Ujian Logo" className="logo" />
      <h1 className="test-name">{test.name}</h1>
      <div className="question-explanation">
        <div className="question-header">
          <h3>Soal {currentQuestionIndex + 1}</h3>
          <span className="question-type">{currentQuestion.questionType}</span>
        </div>
        {currentQuestion.imageUrl && (
          <img 
            src={currentQuestion.imageUrl} 
            alt="Question illustration" 
            className="question-image"
          />
        )}
        <p className="question-text">{currentQuestion.question}</p>
        <div className="options">
          {currentQuestion.options.map((option, optionIndex) => {
            const optionLetter = String.fromCharCode(65 + optionIndex);
            const isCorrect = optionLetter === currentQuestion.correctOption;
            const isUserSelected = optionLetter === userAnswers[currentQuestionIndex];
            return renderOption(option, optionIndex, isCorrect, isUserSelected);
          })}
        </div>
        <div className="explanation">
          <h4>Pembahasan:</h4>
          <p>{currentQuestion.explanation}</p>
        </div>
      </div>
      <div className="navigation-container">
        <button 
          onClick={() => handleQuestionChange(Math.max(0, currentQuestionIndex - 1))}
          disabled={currentQuestionIndex === 0}
          className="nav-button prev-button"
          aria-label="Previous question"
        >
          <i className="fas fa-chevron-left"></i>
        </button>
        <span className="question-count">{currentQuestionIndex + 1} dari {test.questions.length}</span>
        <button 
          onClick={() => handleQuestionChange(Math.min(test.questions.length - 1, currentQuestionIndex + 1))}
          disabled={currentQuestionIndex === test.questions.length - 1}
          className="nav-button next-button"
          aria-label="Next question"
        >
          <i className="fas fa-chevron-right"></i>
        </button>
      </div>
      <div className="dashboard-button-container">
        <button onClick={() => navigate('/dashboard/purchased')} className="back-button">
          <i className="fas fa-home"></i><span>Kembali ke Dashboard</span>
        </button>
      </div>
    </div>
  );
}

export default Explanations;